import $ from "jquery";

$(document).ready(() => {
  $("#hamburger").on("click", () => {
    $("#hamburger").toggleClass("is-active");
    $("#nav-mobile").toggleClass("is-active");
  });

  $("#nav-mobile__functions").on("click", () => {
    $("#nav-mobile__functions")
      .toggleClass("is-active")
      .toggleClass("text-secondary");
  });

  $(window).resize(() => {
    $("#hamburger").removeClass("is-active");
    $("#nav-mobile").removeClass("is-active");
  });
});
